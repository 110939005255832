
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import Dropdown from "@/components/divere/dropdown/Dropdown2.vue";
import NewCardModal from "@/components/divere/modals/forms/NewCardModal.vue";

import Events from "@/components/divere/customers/cards/events-and-logs/Events.vue";

import Earnings from "@/components/divere/customers/cards/statments/Earnings.vue";
import Statement from "@/components/divere/customers/cards/statments/Statement.vue";

export default defineComponent({
  name: "agent-details",
  components: {
    Events,
    Earnings,
    Statement,
    Dropdown,
    NewCardModal,
  },
  setup() {
    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Agents Details", ["Apps", "Agents"]);
    });

    return {};
  },
});
